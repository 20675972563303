export const endpoints = {
    getPMBase: () => 'getPM?symbol=AU,AG,PT,PD,RH&kgx=yes&type=json',  
    getPMBaseWithUnits: (unit: string) => `getPM?symbol=AU,AG,PT,PD,RH&${unit}=yes&type=json`, 
    getPMWithParams: (currency: string, unit: string) => `/getPM?type=json&df=1&tf=1&symbol=AU,AG,PT,PD&currency=${currency}&unit=${unit}&tz=America/New_York&kgx=no`, 
    getBMBase: () => '/getBM?symbol=CU,NI,AL,ZN,PB,UR&kgx=yes&type=json',
    getOilBase: () => '/getValue?ver=2.0&symbol=CL&df=2&tf=2&type=json&kgx=yes', 
    getCurrencyRates: () => '/getUSD?symbol=USD,CAD,EUR,GBP,JPY,CHF,AUD,NZD,CNY,INR,MXN,BRL,ZAR,RUB&type=json&tz=America/New_York&df=1&tf=1&ver=2.0', 
    getCertainBaseMetal: (symbol: string) => `/getBM?symbol=${symbol}&kgx=yes&type=json`,
    getCertainPreciousMetal: (symbol: string) => `/getPM?symbol=${symbol}&kgx=yes&type=json`,  
    getNewsWithParams: (lastId: number, category: string) => `https://cms.prod.kitco.com/rest-api/getlatestnews?amount=10&fromid=${lastId}&category=${category}&order=Asc`, 
    getNews: (category: string) => `https://cms.prod.kitco.com/rest-api/getlatestnews?category=${category}&amount=10&order=Asc`,
    getBaseNews: (category: string) =>`https://kds2-qa.kitco.com/getNEWS?type=json?amount=10&category=${category}&order=Asc`, 
    getBaseNewsWithParams: (lastId: number, category: string) =>`https://kds2-qa.kitco.com/getNEWS?type=json?amount=10&fromid=${lastId}&category=${category}&order=Asc`, 
    getCrypto: (symbol: string) => `https://kdb-api.prod.kitco.com/api/crypto-rates?crypto=${symbol}&money=USD&limit=1&compareToTime=24h`,  
    getHistoryPM: () => '/getHistoryPM?type=json&symbol=AU,AG,PT,PD&currency=USD&unit=OUNCE',
    getLFValue: (date: string) => `/getLFValue?currency=USD&date=${date}&symbol=AU,AG,PT,PD&type=json`,
    sendFeedback: () => 'https://us-central1-goldlive-8ca30.cloudfunctions.net/sendEmail',
}