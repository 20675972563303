export const codeList = {
    financial_widget: (width: string, isTransparent: boolean, colorTheme: 'light' | 'dark', widgetUrl: string) => ` 
    <!-- Kitco Widget BEGIN --> 
    <div class="widget-wrapper" style="width:100%; margin: 50px auto 0 auto;">
        <div id="widget-container" style="margin-bottom: 60px"></div>
     </div>
      <script src="${widgetUrl}/widget.bundle.js"></script>
      <script>
          document.addEventListener('DOMContentLoaded', function() {
            if (typeof window.createFinancialWidget === 'function') {
              const config =  {
                "width": ${width},
                "isTransparent": ${isTransparent},
                "colorTheme": "${colorTheme}"
              }    
              window.createFinancialWidget("widget-container", config); 
            } else {
              console.error('window.createFinancialWidget is not a function')
            }
          })
      </script>
      <!-- Kitco Widget END --> 
    `,
    financial_widget_copy: (width: string, isTransparent: boolean, colorTheme: 'light' | 'dark', widgetUrl: string) => ` 
    <!-- Kitco Widget BEGIN --> 
    <div class="widget-wrapper" style="width:100%; margin: 50px auto 0 auto;">
        <div id="widget-container" style="margin-bottom: 60px"></div>
     </div>
      <script src="${widgetUrl}/widget.bundle.js"></script>
      <script>
          document.addEventListener('DOMContentLoaded', function() {
            if (typeof window.createFinancialWidget === 'function') {
              const config =  {
                "width": "${width}",
                "isTransparent": ${isTransparent},
                "colorTheme": "${colorTheme}"
              }    
              window.createFinancialWidget("widget-container", config); 
            } else {
              console.error('window.createFinancialWidget is not a function')
            }
          })
      </script>
      <!-- Kitco Widget END --> 
  `,
  comodity_widget: (width: string, isTransparent: boolean, colorTheme: 'light' | 'dark', widgetUrl: string) => ` 
 <!-- Kitco Widget BEGIN --> 
  <div class="widget-wrapper" style="width:50%; margin: 50px auto 0 auto;">
      <div id="widget-container-comodity" style="margin-bottom: 60px"></div>
   </div>
    <script src="${widgetUrl}/widgetComodity.bundle.js"></script>
    <script>
        document.addEventListener('DOMContentLoaded', function() {
          if (typeof window.createComodityWidget === 'function') {
            const config =  {
              "width": "${width}",
              "isTransparent": ${isTransparent},
              "colorTheme": "${colorTheme}"
            }    
            window.createComodityWidget("widget-container-comodity", config); 
          } else {
            console.error('window.createComodityWidget is not a function')
          }
        })
    </script>
    <!-- Kitco Widget END --> 
  `,
  comodity_widget_copy: (width: string, isTransparent: boolean, colorTheme: 'light' | 'dark', widgetUrl: string) => ` 
  <!-- Kitco Widget BEGIN --> 
  <div class="widget-wrapper" style="width:50%; margin: 50px auto 0 auto;">
      <div id="widget-container-comodity" style="margin-bottom: 60px"></div>
   </div>
    <script src="${widgetUrl}/widgetComodity.bundle.js"></script>
    <script>
        document.addEventListener('DOMContentLoaded', function() {
          if (typeof window.createComodityWidget === 'function') {
            const config =  {
              "width": "${width}",
              "isTransparent": ${isTransparent},
              "colorTheme": "${colorTheme}"
            }    
            window.createComodityWidget("widget-container-comodity", config); 
          } else {
            console.error('window.createComodityWidget is not a function')
          }
        })
    </script>
    <!-- Kitco Widget END --> 
`,
  kgx_mini_widget: (width: string, isTransparent: boolean, colorTheme: 'light' | 'dark', widgetUrl: string) => ` 
  <!-- Kitco Widget BEGIN --> 
  <div class="widget-wrapper" style="width:50%; margin: 50px auto 0 auto;">
      <div id="widget-container-kgx" style="margin-bottom: 60px"></div>
  </div>
    <script src="${widgetUrl}/
    widgetKgxMini.bundle.js"></script>
    <script>
        document.addEventListener('DOMContentLoaded', function() {
          if (typeof window.createKGXMiniWidget === 'function') {
            const config =  {
              "width": ${width},
              "isTransparent": ${isTransparent},
              "colorTheme": ${colorTheme}
            }    
            window.createKGXMiniWidget("widget-container-kgx", config); 
          } else {
            console.error('window.createKGXMiniWidget is not a function')
          }
        })
    </script>
    <!-- Kitco Widget END --> 
  `,
  kgx_mini_widget_copy: (width: string, isTransparent: boolean, colorTheme: 'light' | 'dark', widgetUrl: string) => ` 
  <!-- Kitco Widget BEGIN --> 
  <div class="widget-wrapper" style="width:50%; margin: 50px auto 0 auto;">
      <div id="widget-container-kgx" style="margin-bottom: 60px"></div>
  </div>
    <script src="${widgetUrl}/widgetKgxMini.bundle.js"></script>
    <script>
        document.addEventListener('DOMContentLoaded', function() {
          if (typeof window.createKGXMiniWidget === 'function') {
            const config =  {
              "width": "${width}",
              "isTransparent": ${isTransparent},
              "colorTheme": "${colorTheme}"
            }    
            window.createKGXMiniWidget("widget-container-kgx", config); 
          } else {
            console.error('window.createKGXMiniWidget is not a function')
          }
        })
    </script>
    <!-- Kitco Widget END --> 
  `,
  metal_bid_widget: (width: string, isTransparent: boolean, colorTheme: 'light' | 'dark', widgetUrl: string) => ` 
  <!-- Kitco Widget BEGIN --> 
  <div class="widget-wrapper" style="width:50%; margin: 50px auto 0 auto;">
      <div id="widget-container-metal-bid" style="margin-bottom: 60px"></div>
  </div>
    <script src="${widgetUrl}/widgetMetalBid.bundle.js"></script>
    <script>
        document.addEventListener('DOMContentLoaded', function() {
          if (typeof window.createMetalBidWidget === 'function') {
            const config =  {
              "width": "${width}",
              "isTransparent": ${isTransparent},
              "colorTheme": "${colorTheme}"
            }    
            window.createMetalBidWidget("widget-container-metal-bid", config); 
          } else {
            console.error('window.createMetalBidWidget is not a function')
          }
        })
    </script>
    <!-- Kitco Widget END --> 
  `,
  metal_bid_widget_copy: (width: string, isTransparent: boolean, colorTheme: 'light' | 'dark', widgetUrl: string) => ` 
  <!-- Kitco Widget BEGIN --> 
  <div class="widget-wrapper" style="width:50%; margin: 50px auto 0 auto;">
      <div id="widget-container-metal-bid" style="margin-bottom: 60px"></div>
  </div>
    <script src="${widgetUrl}/widgetMetalBid.bundle.js"></script>
    <script>
        document.addEventListener('DOMContentLoaded', function() {
          if (typeof window.createMetalBidWidget === 'function') {
            const config =  {
              "width": "${width}",
              "isTransparent": ${isTransparent},
              "colorTheme": "${colorTheme}"
            }    
            window.createMetalBidWidget("widget-container-metal-bid", config); 
          } else {
            console.error('window.createMetalBidWidget is not a function')
          }
        })
    </script>
    <!-- Kitco Widget END --> 
  `,
    embed_code: (config: any, style: any) => 
      `
        <!-- Kitco Widget BEGIN --> 
        <div class="widget-wrapper" style="width:${config?.width ?? '50%'}; margin: 50px auto 0 auto;">
            <div id="${config.container_id}" style="margin-bottom: 60px"></div>
        </div>
          <script src="${config.script_link}"></script>
          <script>
              document.addEventListener('DOMContentLoaded', function() {
                if (typeof window.${config.method_name} === 'function') {
                  const config =  {
                    "width": "${style.width}",
                    "isTransparent": ${style.isTransparent},
                    "colorTheme": "${style.colorTheme}"
                  }    
                  window.${config.method_name}("${config.container_id}", config); 
                } else {
                  console.error('window.${config.method_name} is not a function')
                }
              })
          </script>
          <!-- Kitco Widget END --> 
      `,
      embed_code_copy: (config: any, style: any) => 
        `
          <!-- Kitco Widget BEGIN --> 
          <div class="widget-wrapper" style="width:${config?.width ?? '50%'}; margin: 50px auto 0 auto;">
              <div id="${config.container_id}" style="margin-bottom: 60px"></div>
          </div>
            <script src="${config.script_link_display}"></script>
            <script>
                document.addEventListener('DOMContentLoaded', function() {
                  if (typeof window.${config.method_name} === 'function') {
                    const config =  {
                      "width": "${style.width}",
                      "isTransparent": ${style.isTransparent},
                      "colorTheme": "${style.colorTheme}"
                    }    
                    window.${config.method_name}("${config.container_id}", config); 
                  } else {
                    console.error('window.${config.method_name} is not a function')
                  }
                })
            </script>
            <!-- Kitco Widget END --> 
        `
  }; 