import ApiService from '../api/apiService';
import clsx from 'clsx';
import { categoryBySymbol, shortSymbol, shortSymbolFormatted } from '../common/constants';
import { instruments } from '../components/constants';
import { DateNYTime } from '../components/Date/DateNYTime';
import LinkToKitco from '../components/Link/LinkToKitco';
import { DataObject, Instrument } from '../components/types';
import { fetchDataFromAPI, getLimit, humanizePrice } from '../components/utils';
import useSwitchThemeModeInWidget from '../hooks/useSwitchThemeModeInWidget';
import React, { useEffect, useRef, useState } from 'react';
import { useWindowSize } from 'usehooks-ts';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  SelectGroup,
  SelectLabel
} from '../@/components/ui/select';

interface WidgetProps {
  config: WidgetConfig, 
}

const reasonList: any = {
  gold: {
    'down': 'USD Weakness', 
    'up': 'Majority Sellers',  
  },
  silver: {
    'down': 'USD Strength',
    'up': 'Industrial Demand'
  },
  platinum: {
    'down': 'Auto Sector',
    'up': 'Supply Constraints'
  }
}

const MetalBidWidget = ({config}: WidgetProps) => {
  const [selectedMetal, setSelectedMetal] = useState('gold');
  const [selectedInstrument, setSelectedInstrument] = useState<Instrument>(instruments[0])
  const [metalData, setMetalData] = useState<any>({}); 
  const [data1, setData1] = useState<DataObject | null>(null)
  const {width} = useWindowSize();

  const getFormattedData = (data: BaseDataI[]) => {
    const metalDataDefault: any = {
      gold: {},  
      silver: {}, 
      platinum: {}
    }; 
    data.forEach(item => {
      const name = shortSymbol[item.Symbol]; 
      if(metalDataDefault[name]) {
        metalDataDefault[name] = {
          price: item.Bid,
          changes: [
            { reason: reasonList[name].up, value: item.ChangeTrade, percentage: item.ChangePercentTrade },
            { reason: reasonList[name].down, value: item.ChangeUSD, percentage: item.ChangePercentUSD }
          ],
          totalChange: { value: item.Change, percentage: item.ChangePercentage }
        }
      }
    })
    return metalDataDefault; 
  }

  const getData = async () => {
    try {
      const response = await ApiService.getPMBaseData(); 
      const formattedData = getFormattedData(response.PreciousMetals.PM);
      setMetalData(formattedData); 
    } catch (error) {
      console.log('error', error); 
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const newData = await fetchDataFromAPI(selectedInstrument);
      setData1(newData)
    }

    fetchData()
    const interval = setInterval(fetchData, 10000)

    return () => clearInterval(interval)
  }, [selectedInstrument])

    /* const renderInstrumentOptions = () => {
      const groupedInstruments = {
        'Precious Metals': instruments.filter(i => i.apiType === 'precious'),
        'Base Metals': instruments.filter(i => i.apiType === 'base'),
        'Energy': instruments.filter(i => i.apiType === 'oil')
      }
  
      return (
        <>
          {Object.entries(groupedInstruments).map(([group, groupInstruments]) => (
            <SelectGroup title={group} key={group} >
              <SelectLabel className='text-[16px] dark:text-white font-bold'>{group}</SelectLabel>
              {groupInstruments.map(instrument => (
                      <SelectItem
                        key={instrument.id}
                        value={instrument.id}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        {instrument.name}
                      </SelectItem>
              ))}

            </SelectGroup>
          ))}
        </>
      )
    } */
      const renderInstrumentOptions = () => {
        const groupedInstruments = {
          'Precious Metals': instruments.filter(i => i.apiType === 'precious'),
          'Base Metals': instruments.filter(i => i.apiType === 'base'),
          'Energy': instruments.filter(i => i.apiType === 'oil')
        }
    
        return (
          <>
            {Object.entries(groupedInstruments).map(([group, groupInstruments]) => (
              <optgroup label={group} key={group}>
                {groupInstruments.map(instrument => (
                  <option key={instrument.id} value={instrument.id}>
                    {instrument.name}
                  </option>
                ))}
              </optgroup>
            ))}
          </>
        )
      }
  

  useEffect(() => {
    getData(); 
    const interval = setInterval(getData, 100000)
    return () => clearInterval(interval)
  }, []); 
  
  const formatChange = (value: number, percentage: number) => {
    const valueStr = `${value >= 0 ? '+' : ''}${value?.toFixed(2)}`;
    const percentStr = `(${percentage >= 0 ? '+' : ''}${percentage?.toFixed(2)}%)`;
    return { valueStr, percentStr };
  };

  const data = metalData[selectedMetal];

  const { widgetWrapperRef } = useSwitchThemeModeInWidget(config.colorTheme);

console.log('data1', data1);
  return (
    <div ref={widgetWrapperRef} >


    <div className={`bg-white text-gray-dark dark:bg-gray-dark dark:text-white ${config?.isTransparent ? 'bg-opacity-0' : 'bg-white text-gray-dark'}`} style={{ 
      fontFamily: 'Arial, sans-serif',  
      width: width > 992 ? `${config.width}` : '100%', 
      border: '1px solid #ccc', 
      borderRadius: '4px', 
      padding: '8px',
      fontSize: '12px',
    }}>
      <div style={{ 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'baseline',
        marginBottom: '2px'
      }}>
         <select 
          value={selectedInstrument.id}
          onChange={(e) => {
            const found = instruments.find(i => i.id === e.target.value)
            if (found) {
              setSelectedInstrument(found)
            }
          }}
          className='mr-2 lg:mr-2 p-1 my-2 text-[14px] rounded border bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none'
          /* style={{
            fontSize: '16px',
            fontWeight: 'bold',
            padding: '2px',
            border: 'none',
            background: 'transparent'
          }} */
        >
          {renderInstrumentOptions()}
        </select> 
{/*                         <Select
                          defaultValue={selectedInstrument.id}
                          value={selectedInstrument.id}
                          onValueChange={(value) => {
                            const found = instruments.find(i => i.id === value)
                            if (found) {
                              setSelectedInstrument(found)
                            }
                          }}
                        >
                          <SelectTrigger className={clsx('w-[150px] h-[40px] rounded-none outline-none text-[14px] border-none focus:ring-0 focus:outline-none focus:border-none not_border shadow-none', {
                            'dark:bg-gray dark:text-gray-200 dark:border-none dark:shadow-none not_border': config.colorTheme === 'dark',
                          })}>
                            <SelectValue placeholder='Category' className='text-[12px] text-black'/>
                          </SelectTrigger>
                          <SelectContent className={clsx('outline-none h-[250px] ring-0 focus:ring-0 shadow-none', {
                              'bg-gray border-gray-700 text-gray-200': config.colorTheme === 'dark',  
                          })}>
                          {renderInstrumentOptions()}
                          </SelectContent>
                        </Select> */}
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          {data1?.price && <span style={{ fontWeight: 'bold', fontSize: '16px', marginRight: '2px' }}>{humanizePrice(data1?.price)}</span>}
          <span className={`text-[10px] ${config.colorTheme !=='dark' ? 'text-gray-subtext_dark' : 'text-gray-subtext_light'}`}>Bid</span>
        </div>
      </div>
      <DateNYTime/>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <tbody>
            {data1 && <tr style={{ borderBottom: '1px solid #eee' }}>
                <td className='dark:text-white' style={{ paddingTop: '2px', paddingBottom: '2px' }}>
                   {data1.marketChange >= 0 ? `Change due to Predominant Buyers` : `Change due to Predominant Sellers`}
                </td>

                {data1?.marketChange !== undefined && <td className={clsx('', {
                  'text-green-dark dark:text-green-light': data1.marketChange > 0,
                  'text-red-dark dark:text-red-light': data1.marketChange < 0,
                  'text-gray dark:text-gray-subtext_light': data1.marketChange === 0,
                })} style={{ textAlign: 'right', paddingLeft: '8px' }}>{data1 && data1?.marketChange?.toFixed(getLimit(data1.symbol))}</td>}

                {data1?.marketChangePercent !== undefined && <td className={clsx('', {
                  'text-green-dark dark:text-green-light': data1.marketChangePercent > 0,
                  'text-red-dark dark:text-red-light': data1.marketChangePercent < 0,
                  'text-gray dark:text-gray-subtext_light': data1.marketChangePercent === 0,
                })} style={{ textAlign: 'right', paddingLeft: '8px' }}>{data1.marketChangePercent.toFixed(2)}%</td>}
              </tr>}

              {data1 && <tr style={{ borderBottom: '1px solid #eee' }}>
                <td className='dark:text-white' style={{ paddingTop: '2px', paddingBottom: '2px' }}>
                  {data1?.usdChange >= 0 ? `USD Weakness` : `Majority Sellers`}
                </td>

                {data1?.usdChange !== undefined && <td className={clsx('', {
                  'text-green-dark dark:text-green-light': data1.usdChange > 0,
                  'text-red-dark dark:text-red-light': data1.usdChange < 0,
                  'text-gray dark:text-gray-subtext_light': data1.usdChange === 0,
                })} style={{ textAlign: 'right', paddingLeft: '8px' }}>{data1 && data1.usdChange?.toFixed(getLimit(data1.symbol))}</td>}

                {data1?.usdChangePercent !== undefined && <td className={clsx('', {
                  'text-green-dark dark:text-green-light': data1.usdChangePercent > 0,
                  'text-red-dark dark:text-red-light': data1.usdChangePercent < 0,
                  'text-gray dark:text-gray-subtext_light': data1.usdChangePercent === 0,
                })} style={{ textAlign: 'right', paddingLeft: '8px' }}>{data1.usdChangePercent.toFixed(2)}%</td>}
              </tr>}
{/*           {data?.changes?.map((change: any, index: number) => {
            const { valueStr, percentStr } = formatChange(change.value, change.percentage);
            return (
              <tr key={index} style={{ borderBottom: '1px solid #eee' }}>
                <td style={{ paddingTop: '2px', paddingBottom: '2px' }}>Chg due to {change.reason}</td>
                <td style={{ textAlign: 'right', color: change.value >= 0 ? 'green' : 'red', paddingLeft: '8px' }}>{valueStr}</td>
                <td style={{ textAlign: 'right', color: change.value >= 0 ? 'green' : 'red', paddingLeft: '8px' }}>{percentStr}</td>
              </tr>
            );
          })} */}
          <tr style={{ fontWeight: 'bold', borderTop: '2px solid #999' }}>
            <td className='dark:text-white' style={{ paddingTop: '2px', paddingBottom: '2px' }}>Total {selectedInstrument.name} Change:</td>
            {data1 && <td className={clsx('', {
                  'text-green-dark dark:text-green-light': data1.totalChange > 0,
                  'text-red-dark dark:text-red-light': data1.totalChange < 0,
                  'text-gray dark:text-gray-subtext_light': data1.totalChange === 0, 
            })} style={{ textAlign: 'right', paddingLeft: '8px' }}>
              {data1.totalChange.toFixed(getLimit(data1.symbol))}
            </td>}
           {data1 && <td className={clsx('', {
                  'text-green-dark dark:text-green-light': data1.totalChangePercent > 0,
                  'text-red-dark dark:text-red-light': data1.totalChangePercent < 0,
                  'text-gray dark:text-gray-subtext_light': data1.totalChangePercent === 0,
                })} style={{ textAlign: 'right', paddingLeft: '8px' }}>
              {data1?.totalChangePercent?.toFixed(2)}%
            </td>}
          </tr>
        </tbody>
      </table>

      <div className='text-center mt-1'>
        <LinkToKitco isDarkMode={config.colorTheme === 'dark'}/>
      </div>
    </div>
    </div>
  );
};

export default MetalBidWidget;
