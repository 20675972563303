import clsx from 'clsx';
import { listMenu } from 'components/Sidebar/listMenu';
import { useNavigationSectionHandler } from 'hooks/useNavigationSectionHandler';
import SubNavigation from './SubNavigation';
import { useNavigate, useNavigation } from 'react-router-dom';

function NavigationSection() {
  const { activeOption, pathname } = useNavigationSectionHandler();
  return (
    <div className='flex w-full justify-center pt-[50px] md:px-4'>
      <div className='w-full md:w-full lg:w-full xl:w-[90%] 2xl:w-[70%]'>
        <div className='font-light text-[30px] md:text-[48px] leading-[58px] uppercase'>
          <span className='text-[#23232333] bebas-300'>Widget Categories / </span>
          <span className='text-[#373737] bebas-300'>{activeOption?.short_title}</span>
        </div>
        <div className='flex overflow-x-scroll md:overflow-x-auto justify-between border-b border-[#E2E8F0] pt-2 md:pt-[25px]'>
          {listMenu[0].options &&
            listMenu[0].options.map((item) => (
              <NavigationItem
                key={item.short_title}
                activeOption={activeOption}
                item={item}
              />
            ))}
        </div>
        {activeOption && activeOption.subOptions.length > 1 && <SubNavigation activeOption={activeOption} pathname={pathname}/>}
      </div>
    </div>
  );
}

export default NavigationSection;

interface NavigationItemProps {
  activeOption: SubOption | undefined;
  item: SubOption;
}
const NavigationItem = ({ activeOption, item }: NavigationItemProps) => {
  const navigate = useNavigate(); 
  const handleClick = (path: string) => {
    navigate(path); 
  }
  return (
    <div
      className={clsx('text-[10px] md:text-[12px] text-[#171717] uppercase cursor-pointer px-4', {
        'border-b-2 border-[#0369A1] font-bold mulish-700':
          activeOption?.short_title === item.short_title,
          'mulish-400': activeOption?.short_title !== item.short_title,
      })}
      key={item.short_title}
    >
      <div className='py-2' onClick={() => handleClick(item.subOptions[0].path)}>{item.short_title}</div> 
    </div>
  );
};
