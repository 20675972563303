import { shortSymbol } from "../common/constants";
import { DataObject } from "./types";
import React from "react";

interface PropsI {
    data: DataObject; 
}

export const ChangeExplanation = ({data}: PropsI) => {
    const us = data.usdChange; 
    const gp = data?.changeTrade ?? 0; 
    const tot = data?.totalChange; 
    console.log('us', us, 'gp', gp, 'tot', tot)

    if(us > 0 && gp > 0 && tot > 0) {
        return <div><span>Yes.</span>{`The weakened US Dollar was responsible for ${us.toFixed(2)} of that increase.`}</div>
    } else if(us > 0 && gp < 0 && tot > 0) {
        return <div><span className='font-bold'>No. </span>{`It actually went down ${gp.toFixed(2)} in real terms, but US Dollar weakness makes it appear to have gone the other way.`}</div>
    } else if (us > 0 && gp < 0 && tot < 0) {
        return <div><span className='font-bold'>Yes. </span>{`But had the US Dollar not lost purchasing power it would have dropped ${gp.toFixed(2)}.`}</div>
    } else if(us > 0 && gp < 0 && tot === 0) {
        return <div><span className='font-bold'>No. </span>{`In real terms it actually went down ${gp.toFixed(2)}, but this change was offset by a weaker US Dollar.`}</div>
    } else if(us < 0 && gp < 0 && tot < 0) {
        return <div><span className='font-bold'>Yes. </span>{`The stronger US Dollar was responsible for ${us.toFixed(2)} of that drop.`}</div>
    } else if( us < 0 && gp > 0 && tot > 0) {
        return <div><span className='font-bold'>Yes. </span>{`It actually went up ${gp.toFixed(2)} in real terms, but this change was offset by a stronger US Dollar.`}</div>
    } else if(us > 0 && gp === 0 && tot > 0) {
        return <div><span className='font-bold'>Yes. </span>{`The weakened US Dollar was responsible for ${us.toFixed(2)} of that increase.`}</div>
    } else if(us === 0 && gp === 0) {
        return <div><span className='font-bold'>Yes. </span>{`There was no change in the value of ${shortSymbol[data.symbol]} nor the US Dollar.`}</div>
    } else if(us < 0 && gp === 0 && tot < 0) {
        return <div>{`In real terms gold remained unchanged, but the stronger US Dollar caused the drop in price.`}</div>
    } else if(us === 0 && gp > 0 && tot > 0) {
        return <div><span className='font-bold'>Yes. </span>{`The US Dollar value didn't change and had no impact on the price.`}</div>
    } else if (us === 0 && gp < 0 && tot < 0) {
        return <div><span className='font-bold'>Yes. </span>{`The US Dollar value didn't change and had no impact on the price.`}</div>
    } else if (us < 0 && gp > 0 && tot === 0) {
        return <div><span className='font-bold'>No. </span>{`It actually went up ${gp.toFixed(2)} in real terms, but this change was offset by a stronger US Dollar.`}</div>
    } else if(us < 0 && tot < 0 && gp > 0) {
        return <div><span className='font-bold'>No. </span>{`It actually went up ${gp.toFixed(2)} in real terms, but US Dollar strength makes it appear to have gone the other way.`}</div>
    } else {
        return <div></div>
    }
}