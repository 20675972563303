import React, { useEffect, useRef } from "react";

export default function useSwitchThemeModeInWidget(theme: 'dark' | 'light') {
    const widgetWrapperRef = useRef<HTMLDivElement>(null);
      useEffect(() => {
        if (widgetWrapperRef.current) {
          if (theme === 'dark') {
            widgetWrapperRef.current.classList.add("dark");
          } else {
            widgetWrapperRef.current.classList.remove("dark");
          }
        }
      }, [theme]);

      return {
        widgetWrapperRef
      }
}