import { api } from "api";
import { endpoints } from "./endpoints";

const ApiService  = {
  async getPMBaseData() {
    const response: PMResponse = await api.get(endpoints.getPMBase()); 
    return response; 
  },
  async getPMBaseWithUnitData(unit: string) {
    const response: PMResponse = await api.get(endpoints.getPMBaseWithUnits(unit)); 
    return response; 
  },
  async getBMBaseData() {
    const response: BMResponse = await api.get(endpoints.getBMBase()); 
    return response; 
  },
  async getPMWithParams(currency: string, unit: string) {
    const response: PMResponse = await api.get(endpoints.getPMWithParams(currency, unit)); 
    return response; 
  },
  async getOilBaseData() {
    const response: OilResponse = await api.get(endpoints.getOilBase()); 
    return response; 
  },
  async getCurrency() {
    const response: RatesResponse = await api.get(endpoints.getCurrencyRates()); 
    return response; 
  },
  async getCertainBM(symbol: string) {
    const response: BMCertainResponse = await api.get(endpoints.getCertainBaseMetal(symbol)); 
    return response; 
  },
  async getCertainPM(symbol: string) {
    const response: PMCertainResponse = await api.get(endpoints.getCertainPreciousMetal(symbol)); 
    return response; 
  },
  async getNews(lastId: number = 0, category: string = '') {
    const url = lastId !== 0 ? endpoints.getNewsWithParams(lastId, category) : endpoints.getNews(category); 
    const response: NewsResponse = await api.get(url); 
    return response; 
  },
  async getBaseNews(lastId: number = 0, category: string = '') {
    const url = lastId !== 0 ? endpoints.getBaseNewsWithParams(lastId, category) : endpoints.getBaseNews(category); 
    const response: NewsResponse = await api.get(url); 
    return response; 
  },
  async getCrypto(symbol: string) {
    const response: CryptoResponse = await api.get(endpoints.getCrypto(symbol)); 
    return response; 
  },
  async getHistoryPM() {
    const response: any = await api.get(endpoints.getHistoryPM()); 
    return response; 
  },
  async getLFValue(data: string) {
    const response: LFResponse = await api.get(endpoints.getLFValue(data)); 
    return response; 
  },
  async sendFeedback(data: any) {
    const response: any = await api.post(endpoints.sendFeedback(), {...data}); 
    return response; 
  },
}

export default ApiService; 