import EmbedCode from 'components/EmbedCode/EmbedCode';
import FinancialWidget from 'components/FinancialWidget';
import ButtonsSection from 'components/SettingsWidget/ButtonsSection';
import SettingsWidget from 'components/SettingsWidget/SettingsWidget';
import { useSettingsWidgetHandler } from 'hooks/useSettingsWidgetHandler';

function FinancialWidgetPage() {
  const {
    isContainerChecked,
    isTransparentChecked,
    widthValue,
    theme,
    handleChange,
    handleContainerChecked,
    handleThemeChange,
    handleTrasparentChecked,
    handleResetSettings,
    handleApply, 
    config
  } = useSettingsWidgetHandler({ widthDefault: '500' });

  return (
    <div className='pt-[20px] pb-[40px] w-full flex flex-col items-center'>
      <div className='text-[30px] font-bold pb-4'>{'Kitco Gold Index (KGX)'}</div>
      <div className='w-full lg:w-1/2 mx-auto flex flex-col items-center'>
      
        <FinancialWidget config={config}/>

        <SettingsWidget
          widthValue={widthValue}
          theme={theme}
          isTransparentChecked={isTransparentChecked}
          isContainerChecked={isContainerChecked}
          handleChange={handleChange}
          handleContainerChecked={handleContainerChecked}
          handleThemeChange={handleThemeChange}
          defaultWidth='400'
          handleTrasparentChecked={handleTrasparentChecked}
        />

        <ButtonsSection  
          isTransparentChecked={isTransparentChecked} 
          widthValue={widthValue} 
          handleApply={handleApply}
          theme={theme} 
          widgetType='financial_widget'
          handleResetSettings={handleResetSettings} />

        <EmbedCode
          width={widthValue}
          isTransparent={isTransparentChecked}
          colorTheme={theme}
          widgetType='financial_widget'
        />
      </div>
    </div>
  );
}

export default FinancialWidgetPage;
